<template>
  <div>
    <div v-if="!$apollo.loading">
      <b-card title="Busque um jogo 🔍">
        <b-card-text>No campo abaixo busque por nome:</b-card-text>
        <b-form-input
          v-model="searchQuery"
          icon-no-border
          placeholder="Digite e pressione ENTER"
          class="input-rounded-full"
          icon="icon-search"
          id="live-query"
          icon-pack="feather"
          :state="docState"
          aria-describedby="input-live-help input-live-feedback"
          @keyup.native="searchUser()"
        />
        <b-form-invalid-feedback id="input-live-feedback">
          Pelo menos 3 letras
        </b-form-invalid-feedback>
      </b-card>
      <general-games-list
        :searched-results="queryResult"
        :context-actions="[contextAction]"
        @addGameGroup="retransmit"
      />
      <nav aria-label="...">
        <ul class="pagination">
          <li
            v-if="pageInfo.currentPage - 1 > 0"
            class="page-item"
          >
            <span
              class="page-link pointer"
              @click="paginate(-1)"
            >Anterior</span>
          </li>
          <li class="page-item active">
            <span class="page-link">
              {{ pageInfo.currentPage }}
            </span>
          </li>
          <li
            v-if="pageInfo.currentPage < pageInfo.lastPage"
            class="page-item"
          >
            <span
              class="page-link pointer"
              @click="paginate(+1)"
            >Proxima</span>
          </li>
        </ul>
      </nav>

    </div>
    <div v-else>
      <logo-loader-animation />
    </div>
  </div>

</template>

<script>
import {
  BCard, BCardText, BLink, BFormInput, BFormInvalidFeedback,
} from 'bootstrap-vue'
import GeneralGamesList from '@/components/games/GeneralGamesList.vue'
import gql from 'graphql-tag'
import { _ } from 'vue-underscore'
import LogoLoaderAnimation from '@/components/misc/LogoLoaderAnimation.vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BFormInput,
    GeneralGamesList,
    BFormInvalidFeedback,
    underscore: _,
    LogoLoaderAnimation,

  },
  props:['contextActions','notShowing'],
  data() {
    return {
      searchQuery: '',
      currentPage: 1,
      searchResults: [],
      queryResult: null,
      pageToSearch: 1,
      pageInfo: {},
      contextAction:'',
    }
  },
  computed: {
    docState() {
      return this.searchQuery.length > 2
    },
  },
  created() {
    if(this.contextActions == null){
      this.contextAction = 'details'
    }else{
      this.contextAction = this.contextActions
    }
    this.searchUser()
  },
  methods: {
    searchUser() {
      if (this.searchQuery == '' || ! this.docState) {
        this.$apollo.query({
          query: gql`query{
            Games(status:"ENABLED"){
              id,
              name,
              provider{
                name
              }
            }
          }`,
          client: 'gameClient',
        }).then(data => {
          this.queryResult = data.data.Games;
          this.mapResult = this.queryResult;
          if(this.notShowing != null){
            this.queryResult = this.mapResult.filter( i => this.notShowing.indexOf(i.id) == -1)
          }
        }).catch(error => {
          console.error(error)
        })
      }else{
        this.queryResult = this.mapResult.filter(item => item.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
        if(this.notShowing != null){
          this.queryResult = this.queryResult.filter( i => this.notShowing.indexOf(i.id) == -1)
        }
      }
    },
    
    paginate(eV) {
      if (this.pageToSearch == 1) {
        this.pageToSearch = 2
      } else {
        this.pageToSearch += eV
      }
      this.searchUser()
    },
    retransmit(id){
      this.$emit('addGameGroup',id)
    }
  },
  watch:{
    notShowing(){
      this.searchUser()

      // this.queryResult = this.mapResult.filter( i => this.notShowing.indexOf(i.id) == -1)

    }
  },
}
</script>

<style>

</style>
