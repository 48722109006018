<template>
  <div>
    <div v-if="!$apollo.loading">
      <b-card title="Crie/Edite um grupo de jogos">
        <b-row>
          <b-col
            cols="12"
            lg="4"
          >
            <b-form-group v-if="group">
              <label
                class="mt-1"
                for="promotion-name"
              >ID</label>
              <b-form-input
                v-model="group.id"
                icon-no-border
                class="input-rounded-full"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            lg="4"
          >
            <b-form-group v-if="group">
              <label
                class="mt-1"
                for="promotion-name"
              >GRUPO DE CARTEIRA</label>
              <b-form-input
                v-model="group.group_code"
                icon-no-border
                class="input-rounded-full"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            lg="4"
          >
            <b-form-group v-if="group">
              <label
                class="mt-1"
                for="promotion-name"
              >CÓDIGO</label>
              <b-form-input
                v-model="group.code"
                icon-no-border
                class="input-rounded-full"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
          <b-button
              class="act-button mt-2 w-100"
              variant="gradient-primary"
              @click="updateGroup"
            >
              Atualizar grupo
            </b-button>
          </b-col>
        </b-row>
        <alert-toast
          ref="warning-toast"
          :message="this.warningMessage"
          :variant="this.messageVariant"
        />

      </b-card>
      <b-card title="Jogos deste grupo">
        <general-games-list
        v-if="games.length>0"
        :searched-results="games"
        :context-actions="['remove-from-group']"
        @remove="removeGame"
      />
      <b-button
        class="act-button mt-2 w-100"
        variant="gradient-primary"
        @click="addGame = !addGame"
      >
        Adicionar jogo
      </b-button>
      </b-card>

      <div v-if="addGame" id="add-game">
        <games-view
          @addGameGroup="addGameG"
          :context-actions="['add-game-group']"
          :not-showing="groupGames"
        />
      </div>
    </div>
    <div v-else>
      <logo-loader-animation />
    </div>
  </div>

</template>

<script>
import {
  BCard, BRow, BButton, BCardText, BCol, BLink, BFormInput, BFormGroup, BFormSelect, BFormSelectOption, BFormCheckbox, BFormInvalidFeedback, BInputGroup, BInputGroupAppend, BInputGroupPrepend,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import { _ } from 'vue-underscore'
import LogoLoaderAnimation from '@/components/misc/LogoLoaderAnimation.vue'
import AlertToast from '@/components/misc/AlertToast.vue'
import GeneralGamesList from '@/components/games/GeneralGamesList.vue'
import GamesView from '@/views/games/GamesList.vue'

export default {
  components: {
    BButton,
    BCard,
    BCardText,
    BLink,
    BFormInput,
    BFormInvalidFeedback,
    underscore: _,
    GeneralGamesList,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    AlertToast,
    LogoLoaderAnimation,
    BFormGroup,
    BFormCheckbox,
    BRow,
    BFormSelect,
    BFormSelectOption,
    GamesView
  },
  data() {
    return {
      rKey: 1,
      group:null,
      messageVariant:'',
      warningMessage:'',
      groupGames:[],
      games:[],
      addGame:false
    }
  },
  computed: {
    
  },
  apollo: {
    Group: {
      query: gql`query($id:ID){
        Group(id:$id){
          id,
          code,
          group_code
        }
      }`,
     variables () {
        return {
          id: this.$route.params.id,
        }
      },
      client: 'gameClient',
      // result({ data }) {
      //  console.log(data)
      // },
    },
    GroupsGames:{
      query: gql`query($id:ID){
        GroupsGames(group_id:$id){
          id,
          game_id,
        }
      }`,
     variables () {
        return {
          id: this.$route.params.id,
        }
      },
      client: 'gameClient',
      result({ data }) {
       this.groupGames = data.GroupsGames.map(g=>g.game_id)
      },
    },
    Games:{
      query: gql`query($id:[ID]){
        Games(ids:$id){
          id,
          name,
          provider{
            name
          }
        }
      }`,
     variables () {
        return {
          id: this.groupGames,
        }
      },
      client: 'gameClient',
      result({ data }) {
       this.games = data.Games
      },
    }
  },
  watch: {
    Group() {
     this.group = Object.fromEntries(Object.entries(this.Group))
    },
    groupGames(){

    }
  },
  created() {
  },
  methods: {
    updateGroup() {
      this.$apollo.mutate({
        mutation: gql`mutation(
          $id: ID,
          $code: String,
          $group_code: String
          ){
          updateGroup(
            id:$id,
            code:$code,
            group_code:$group_code,
          ){
            id
          }
        }`,
        variables: {
          id: this.group.id,
          code: this.group.code,
          group_code: this.group.group_code,
        },
        client: 'gameClient',
      }).then(data => {
        this.warningMessage = 'Grupo atualizado com sucesso!'
        this.messageVariant = 'success'
        this.$refs['warning-toast'].showAlert()
        this.groupSavedId = data.data.updateGroup.id
      }).catch(error => {
        console.error(error)
      })
    },
    deleteGroupGameRelation(id,gid){
      this.groupGames = this.groupGames.filter(g => g.game_id != gid);
      this.games = this.games.filter(g => g.id != gid);
      this.$apollo.mutate({
        mutation: gql`mutation(
          $id: ID!,
          ){
          removeGameGroup(
            id:$id,
          ){
            id
          }
        }`,
        variables: {
          id: id,
        },
        client: 'gameClient',
      }).then(data => {
        this.warningMessage = 'Jogo removido com sucesso!'
        this.messageVariant = 'success'
        this.$refs['warning-toast'].showAlert()
        // this.removedGameGroup = data.data.removeGameGroup.id
      }).catch(error => {
        console.error(error)
      })
    },
    addGameRelation(game_id){
      this.groupGames.push(game_id)
      this.$apollo.mutate({
        mutation: gql`mutation(
          $game_id: ID!,
          $group_id:ID!
          ){
          addGameGroup(
            group_id:$group_id,
            game_id:$game_id,
          ){
            id
          }
        }`,
        variables: {
          game_id: game_id,
          group_id: this.$route.params.id
        },
        client: 'gameClient',
      }).then(data => {
        this.warningMessage = 'Jogo adicionado com sucesso!'
        this.messageVariant = 'success'
        this.$refs['warning-toast'].showAlert()
        this.addGameGroup = data.data.addGameGroup.id
        // this.$apolloProvider.clients.gameClient.reFetchObservableQueries()
      }).catch(error => {
        console.error(error)
      })
    },
    removeGame(id){
      let relate = this.GroupsGames.filter(gg=>gg.game_id == id)
      this.deleteGroupGameRelation(relate[0].id, id)
    },
    addGameG(id){
      this.addGameRelation(id)
    }
  },
}
</script>

<style>

</style>
