<template>
  <b-card title="Lista de jogos" id="cardo">
    <b-table
      :items="searchedResults"
      :fields="fields"
      striped
      responsive
      class="data-table"
    >
      <template #cell(provider)="data">
        {{data.item.provider.name}}
      </template>
      <template #cell(actions)="data">
        <feather-icon
          v-if="contextActions == 'remove-from-group'"
          icon="DeleteIcon"
          class="ml-2 text-danger pointer"
          @click="$emit('remove',data.item.id)"
        />
        <feather-icon
          v-else-if="contextActions == 'add-game-group'"
          icon="PlusIcon"
          class="ml-2 text-success pointer"
          @click="$emit('addGameGroup',data.item.id)"
        />
        <feather-icon
          v-else
          icon="EditIcon"
          class="ml-2 text-success pointer"
          @click="edit(data.item.id);"
        />
      </template>
    </b-table>
  </b-card>
</template>
<script>
import {
  BTable, BFormCheckbox, BButton, BCard, BRow, BCol, BBadge,BCardText, BLink
} from 'bootstrap-vue'
import JsonExcel from "vue-json-excel";

import gql from 'graphql-tag'
import {_} from 'vue-underscore';

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BCardText,
    BBadge,
    BLink,
    underscore: _,
    JsonExcel,
  },
  props:['searchedResults', 'contextActions', 'addInfo', 'customFields', 'pageInfo'],
  data() {
    return {
      fields: ['id', 'name', 'provider',{ key: 'actions', label: 'Ações' }],
    }
  },
  apollo:{
    
  },
  watch:{
    searchedResults: function(){
    },
    
  },
  methods:{
    formatSave(e){
    },
    cellDecoupe(value, key, item){
      let obj = item.documents.filter(d => d.type == key.toUpperCase())
      return obj.length > 0 ? obj[0].value : '-'
    },
    checkContains(index){
     return _.contains(this.contextActions,index); 
    },
    
  },
  created(){
    
  }
}
</script>

<style>
  .floater{
    /* position: absolute; */
    z-index: 99;
  }
  .data-table tbody{
    padding-bottom: 30px;
  }
  #export-button{
    position: absolute;
    top: 10px;
    right: 10px;
  }
  #cardo{
    position:relative
  }

</style>